<template>
    <div class="total-wrap">
        <div class="total-item">
            <div class="number device">
                {{data.devices}}
            </div>
            <div class="name">设备总数</div>
        </div>
        <div class="total-item">
            <div class="number alarm">
                {{data.alarms}}
            </div>
            <div class="name">告警总数</div>
        </div>
        <div class="total-item">
            <div class="number finish">
                {{data.finished}}
            </div>
            <div class="name">已处理</div>
        </div>
        <div class="total-item">
            <div class="number untreated">
                {{data.unprocessed}}
            </div>
            <div class="name">未处理</div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'statisticsNumber',
    data() {
        return {
            timer: null,
            data: {
                alarms: 0,
                devices: 0,
                finished: 0,
                unprocessed: 0
            }
        }
    },
    methods: {
        loadData() {
            this.$http.get('/statisticsTotal').then(res => {
                this.data = res
            })
        }
    },
    mounted() {
        this.loadData()
        this.timer = setInterval(() => {
            this.loadData()
        },30000)
    },
    unmounted() {
        if (this.timer != null) {
            clearInterval(this.timer)
            this.timer = null
        }
    }
}
</script>
<style scoped>
.total-wrap {
    display: flex;
    align-items: center;
}
.total-item {
    flex: 1;
    margin-top: 15px;
    padding: 10px 5px;
    background-image: url('../assets/images/font-bg@2x.png');
    background-size: 102px 50px;
    background-repeat: no-repeat;
    background-position: bottom center;
    text-align: center;
    color: #ffffff;
}
.total-item > .number {
    line-height: 1;
    font-size: 40px;
    color: #ffffff;
    font-family: DINCondensed-Bold;
}
.total-item > .number.device {
    color: #f56c7f;
}
.total-item > .number.alarm {
    color: #9cf2ff;
}
.total-item > .number.finish {
    color: #ff9300;
}
.total-item > .number.untreated {
    color: #349dfd;
}
.total-item > .name {
    font-size: 14px;
    color: #ffffff;
    height: 20px;
    line-height: 20px;
}
</style>