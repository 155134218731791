<template>
	<div class="ranking">
		<div class="ranking-item" v-for="item in rankingData" :key="item">
			<div class="name">{{item.name}}</div>
			<div class="progress-wrap">
				<div class="progress-bar-wrap">
					<div class="progress-bar">
						<div class="progress-bar-real" :style="{width: (item.alarmTotal / total)+'%'}"></div>
					</div>
				</div>
				<div class="progress-number">{{item.alarmTotal}}</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {
			timer: null,
			rankingData: []
		}
	},
	computed: {
		total() {
			let total = 0
			this.rankingData.forEach(e => {
				total = total + e.alarmTotal
			});
			return total
		}
	},
	methods: {
		loadData() {
			this.$http.get('/deviceAlarmRanking').then(res => {
				this.rankingData = res
			})
		}
	},
	mounted() {
		this.loadData()
		this.timer = setInterval(() => {
			this.loadData()
		}, 30000);
	},
	unmounted() {
		if( this.timer != null) {
			clearInterval(this.timer)
			this.timer = null
		}
	}
}
</script>
<style scoped>
.ranking {
	padding-top: 20px;
}
.ranking-item {
	padding: 5px 0;
}
.ranking-item > .name {
	font-size: 14px;
	color: #fff;
}
.progress-wrap {
	display: flex;
	align-items: center;
}
.progress-wrap > .progress-bar-wrap {
	flex: 1;
	width: 100%;
	height: 20px;
	margin-top: 6px;
}
.progress-bar-wrap .progress-bar {
	position: relative;
	width: 100%;
	height: 8px;
	background-color: #14314c;
	position: relative;
}
.progress-bar-wrap .progress-bar-real {
	position: absolute;
	left: 0;
	width: 0;
	height: 8px;
	display: block;
	background: #08e2ed;
	transition: width ease-in-out .3s;
}
.progress-wrap > .progress-number {
	padding: 0 10px;
	width: 60px;
	color: #08e2ed;
	font-family: DINCondensed-Bold;
	box-sizing: border-box;
	line-height: 1;
}
</style>