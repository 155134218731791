import { createApp } from 'vue'
import App from './App.vue'
const app = createApp(App)

// 引入echarts
import echarts from 'echarts'
app.config.globalProperties.$echarts = echarts

// 添加http为公共方法
import http from '@/api/http'
app.config.globalProperties.$http = http

import scroll from 'vue-seamless-scroll'
app.use(scroll)

// 引入字体css
import '@/assets/font/font.css'
app.mount('#app')