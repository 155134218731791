import axios from 'axios';

// 创建链接
const http = axios.create({
    baseURL: process.env.NODE_ENV === 'development' ? "http://localhost:8009/screen" : "https://api.cng.fsgyhlw.com/screen",
    timeout: 5000,
    headers: {
        'Content-Type': 'application/json',
        'token': 'cng@123'
    }
})

// 请求拦截器
http.interceptors.request.use(config => {
    return config
}, error => {
    return Promise.reject(error)
})

// 响应拦截器
http.interceptors.response.use(response => {
    const data = response.data
    const code = response.data.code
    if (code != 0) {
        switch (code) {
            case 405:
            case 10001:
                console.error(data.log)
                break;
        }
        return Promise.reject(data)
    }
    return data.data
}, error => {
    if (error && error.response) {
        const status = error.response.status
        switch (status) {
            case 400:
                break;
            default:
                console.error('服务器链接错误:' + error.response.status)
                break;
        }
    } else {
        console.error('无法链接到服务器')
    }
    return Promise.reject(error.response)
})

/**
 * Get方法
 * @param url 
 * @param params 
 * @returns {Promise}
 */
export function get(url, params = {}) {
    return new Promise((resolve, reject) => {
        http.get(url, {
                params: params
            })
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}

/**
 * Post请求
 * @param url 
 * @param data 
 * @returns {Promise}
 */
export function post(url, data = {}) {
    return new Promise((resolve, reject) => {
        http.post(url, data)
            .then(response => {
                resolve(response.data)
            }, error => {
                reject(error)
            })
            .catch(error => {
                reject(error)
            })
    })
}

/**
 * Put请求
 * @param url 
 * @param data 
 * @returns {Promise}
 */
export function put(url, data = {}) {
    return new Promise((resolve, reject) => {
        http.put(url, data)
            .then(response => {
                resolve(response.data)
            }, error => {
                reject(error)
            })
            .catch(error => {
                reject(error)
            })
    })
}

/**
 * Delete请求
 * @param url 
 * @param data 
 * @returns {Promise}
 */
export function del(url, params = {}) {
    return new Promise((resolve, reject) => {
        http.delete(url, {
                params: params
            })
            .then(response => {
                resolve(response.data)
            }, error => {
                reject(error)
            })
            .catch(error => {
                reject(error)
            })
    })
}

export default http