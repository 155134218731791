<template>
    <div id="typePercent">

    </div>
</template>
<script>
export default {
    data() {
        return {
            timer: null,
            chartsTimer: null,
            chartsSpeed: 3000,
            charts: null,
            currentIndex: 0,
            echartsData: [],
            colors: ['#ff9300','#ffb734','#e43d55','#ff0000','#349dfd','#08e2ed'],
        }
    },
    computed: {
        option() {
            let echartsLegendData = []
            let seriesData = []
            this.echartsData.forEach((e, i) => {
                let colorIndex = i
                if (i >= this.colors.length) {
                    colorIndex = Math.floor(Math.random() * this.colors.length)
                }
                const item = {'value':e.value, 'name': e.name, itemStyle: {
                    color: this.colors[colorIndex]
                }}
                echartsLegendData.push(e.name)
                seriesData.push(item)
            })
            return {
                tooltip: {
                    trigger: 'item',
                    formatter: '{a} <br/>{b}: {c} ({d}%)'
                },
                title: {
                    text: '总占比',
                    left: '21%',
                    top: '55%',
                    textStyle: {
                        color: '#fff',
                        fontSize: 12,
                        align: 'center'
                    }
                },
                legend: {
                    orient: 'vertical',
                    right: 10,
                    top: 30,
                    textStyle: {
                        color: '#fff',
                        lineHeight: 16,
                        padding: [10, 0]
                    },
                    data: echartsLegendData
                },
                icon: 'circle',
                series: [
                    {
                        name: '设备告警类型占比',
                        type: 'pie',
                        radius: ['50%', '70%'],
                        avoidLabelOverlap: false,
                        label: {
                            show: false,
                            fontSize: 30,
                            position: 'center',
                            padding: [0,0,20,0],
                            color: '#fff',
                            formatter: '{d}%',
                            fontFamily: 'DINCondensed-Bold'
                        },
                        emphasis: {
                            label: {
                                show: true,
                            }
                        },
                        labelLine: {
                            show: false
                        },
                        left: -220,
                        itemStyle: {
                            borderWidth: 5,
                            borderColor: '#101534'
                        },
                        data: seriesData
                    }
                ]
            }
        }
    },
    methods: {
        initEcharts() {
            this.charts = this.$echarts.init(document.getElementById('typePercent'))
        },
        autoHighlight() {
            this.chartsTimer = setInterval(() => {
                // 取消前面高亮
                this.charts.dispatchAction({
                    type: 'downplay',
                    seriesIndex: 0,
                    dataIndex: this.currentIndex
                })
                //
                this.currentIndex = (this.currentIndex + 1) % this.option.series[0].data.length
                // 高亮当前图形
                this.charts.dispatchAction({
                    type: 'highlight',
                    seriesIndex: 0,
                    dataIndex: this.currentIndex
                })
            }, this.chartsSpeed)
        },
        bindMouse() {
            this.charts.on('mouseover', (e) => {
                if (e.dataIndex != this.currentIndex) {
                    this.charts.dispatchAction({
                        type: 'downplay',
                        seriesIndex: 0,
                        dataIndex: this.currentIndex
                    })
                }
            })
            this.charts.on('mouseout', (e) => {
                this.currentIndex = e.dataIndex
                this.charts.dispatchAction({
                    type: 'highlight',
                    seriesIndex: 0,
                    dataIndex: e.dataIndex
                })
            })
        },
        loadData() {
            this.$http.get('/alarmTypePercent').then(res => {
                this.echartsData = res
                this.charts.setOption(this.option)
            })
        }
    },
    mounted() {
        this.initEcharts()
        this.loadData()
        this.timer = setInterval(() => {
            this.loadData()
        }, 30000);
        this.$nextTick(() => {
            this.charts.dispatchAction({
                type: 'highlight',
                seriesIndex: 0,
                dataIndex: this.currentIndex
            })
            this.autoHighlight()
            this.bindMouse()
        })
    },
    unmounted() {
        if (this.charts != null) {
            this.charts.dispose()
            this.charts = null
        }
        if (this.chartsTimer != null) {
            clearInterval(this.chartsTimer)
            this.chartsTimer = null
        }
        if (this.timer != null) {
            clearInterval(this.timer)
            this.timer = null
        }
    }
}
</script>
<style scoped>
#typePercent {
    width: 100%; 
    height: 100%;
}
</style>