<template>
	<span v-text="nowDateTime"></span>
</template>
<script>
export default {
	data() {
		return {
			nowDateTime: "",
			week: ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'],
			timer: null
		}
	},
	methods: {
		updateTime: function() {
			let now = new Date();
			let time = this.zeroPadding(now.getHours(), 2) + ':' + this.zeroPadding(now.getMinutes(), 2) + ':' + this.zeroPadding(now.getSeconds(), 2);
			let date = this.zeroPadding(now.getFullYear(), 4) + '-' + this.zeroPadding(now.getMonth()+1, 2) + '-' + this.zeroPadding(now.getDate(), 2);
			// let date = this.zeroPadding(now.getFullYear(), 4) + '-' + this.zeroPadding(now.getMonth()+1, 2) + '-' + this.zeroPadding(now.getDate(), 2) + ' ' + this.week[now.getDay()]
			this.nowDateTime = date + " " + time;
		},
		zeroPadding: function(num, digit) {
			var zero = '';
			for(var i = 0; i < digit; i++) {
				zero += '0';
			}
			return (zero + num).slice(-digit);
		}
	},
	mounted() {
		this.timer = setInterval(this.updateTime, 1000);
	},
	unmounted() {
		if (this.timer != null) {
			clearInterval(this.timer)
			this.timer = null
		}
	}
}
</script>