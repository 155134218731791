<template>
    <div class="alarm-wrap">
        <div class="alarm-wrap-header">
            <div class="alarm-col col-1">序号</div>
            <div class="alarm-col col-2">设备名称</div>
            <div class="alarm-col col-3">告警类型</div>
            <div class="alarm-col col-4">当前值</div>
            <div class="alarm-col col-5">告警时间</div>
            <div class="alarm-col col-6">处理状态</div>
        </div>
        <div id="scrollContainer" class="alarm-wrap-body">
            <ul id="showContainer">
                <li class="alarm-row" v-for="(item, index) in data" :key="index">
                    <div class="alarm-col col-1">{{index + 1}}</div>
                    <div class="alarm-col col-2">{{item.device.name}}</div>
                    <div class="alarm-col col-3">{{item.title}}</div>
                    <div class="alarm-col col-4">{{item.currentValue}}</div>
                    <div class="alarm-col col-5">{{item.createTime}}</div>
                    <div class="alarm-col col-6" :style="[item.status > 1 ? 'color: #08e2ed;' : 'color: #ff415e;']">
                        <span v-if="item.status > 1">已处理</span>
                        <span v-else>未处理</span>
                    </div>
                </li>
            </ul>
            <ul id="hideContainer"></ul>
        </div>
    </div>
</template>
<script>
export default {
    name: 'alarmList',
    data() {
        return {
            timer: null,
            data: [],
            scrollTimer: null,
            showContainer: null,
            hideContainer: null,
            scrollContainer: null,
            scrollSpeed: 30,
        }
    },
    methods: {
        loadData() {
            this.$http.get('/alarmLogs').then(res => {
                this.data = res
                this.initScroll()
            })
        },
        initScroll() {
            this.clearIntervalScrollTimer()
            this.showContainer = document.getElementById('showContainer')
            this.hideContainer = document.getElementById('hideContainer')
            this.scrollContainer = document.getElementById('scrollContainer')
            this.hideContainer.innerHTML = this.showContainer.innerHTML
            this.scrollContainer.scrollTop = 0 // 开始无滚动时设置0
            // 设置定时起，参数t用在这为间隔时间
            this.scrollTimer = setInterval(this.scrollStart, this.scrollSpeed);
            // 鼠标移入暂停滚动，销毁定时器
            this.scrollContainer.onmouseover = () => {
                this.clearIntervalScrollTimer()
            }
            // 鼠标移除继续滚动
            this.scrollContainer.onmouseout = () => {
                this.scrollTimer = setInterval(this.scrollStart, this.scrollSpeed);
            }
        },
        scrollStart() {
            if (this.scrollContainer.scrollTop >= this.showContainer.scrollHeight) {
                this.scrollContainer.scrollTop = 0
            } else {
                this.scrollContainer.scrollTop++
            }
        },
        clearIntervalScrollTimer() {
            if (this.scrollTimer != null) {
                clearInterval(this.scrollTimer)
                this.scrollTimer = null
            }
        }
    },
    mounted() {
        this.loadData()
        this.timer = setInterval(() => {
            this.loadData()
        }, 30000);
    },
    unmounted() {
        this.clearIntervalScrollTimer()
        if (this.timer != null) {
            clearInterval(this.timer)
            this.timer = null
        }
    }
}
</script>
<style scoped>
.alarm-wrap {
    width: 100%;
    height: 300px;
    overflow: hidden;
    box-sizing: border-box;
    padding: 20px;
}
.alarm-wrap-header {
    width: 100%;
    height: 40px;
    display: block;
    color: #ffffff;
}
.alarm-wrap .alarm-wrap-body {
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.alarm-wrap .alarm-wrap-body > ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
.alarm-wrap .alarm-row {
    width: 100%;
    height: 40px;
    line-height: 40px;
    display: block;
    overflow: hidden;
}
.alarm-wrap .alarm-col {
    display: inline-block;
    padding-right: 5px;
    box-sizing: border-box;
    text-align: center;
    color: #ffffff;
}
.alarm-wrap .alarm-col.col-1 {
    width: 40px;
}
.alarm-wrap .alarm-col.col-2 {
    width: 130px;
}
.alarm-wrap .alarm-col.col-3 {
    width: 130px;
}
.alarm-wrap .alarm-col.col-4 {
    width: 130px;
}
.alarm-wrap .alarm-col.col-5 {
    width: 180px;
}
.alarm-wrap .alarm-col.col-6 {
    width: 120px;
}
</style>