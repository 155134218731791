<template>
    <div id="timeCurve"></div>
</template>
<script>
export default {
    data() {
        return {
            timer: null,
            charts: null,
            timeSlot: 'today',
            todayData: [],
            weekData: [],
            monthData: []
        }
    },
    computed: {
        option() {
            let xData = []
            let todayYData = []
            let weekYData = []
            let monthYData = []
            this.todayData.forEach(e => {
                xData.push(e.name)
                todayYData.push(e.value)
            })
            this.weekData.forEach(e => {
                weekYData.push(e.value)
            })
            this.monthData.forEach(e => {
                monthYData.push(e.value)
            })
            return {
                grid: {
                    left: '0%',
                    right: '0%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    axisLine: {
                        lineStyle: {
                            color: '#fff'
                        }
                    },
                    axisLabel: {
                        interval: 0,
                        rotate: -45
                    },
                    data: xData
                },
                yAxis: {
                    type: 'value',
                    nameTextStyle: {
                        color: '#fff'
                    },
                    axisLine: {
                        lineStyle: {
                            color: '#fff'
                        }
                    },
                    splitLine: {
                        lineStyle: {
                            color: '#2c3960'
                        }
                    }
                },
                series: [{
                    data: todayYData,
                    type: 'line',
                    smooth: true,
                    lineStyle: {
                        color: '#ffb734'
                    }
                },{
                    data: weekYData,
                    type: 'line',
                    smooth: true,
                    lineStyle: {
                        color: '#08e2ed'
                    }
                },{
                    data: monthYData,
                    type: 'line',
                    smooth: true,
                    lineStyle: {
                        color: '#f56c7f'
                    }
                }]
            }
        }
    },
    methods: {
        initEcharts() {
            this.charts = this.$echarts.init(document.getElementById('timeCurve'))
        },
        loadData() {
            this.$http.get('/alarmStatisticsByDeviceWithTimeSlot/today').then(res => {
                this.todayData = res
                this.$nextTick(() => {
                    this.charts.setOption(this.option)
                })
            })
            this.$http.get('/alarmStatisticsByDeviceWithTimeSlot/week').then(res => {
                this.weekData = res
                this.$nextTick(() => {
                    this.charts.setOption(this.option)
                })
            })
            this.$http.get('/alarmStatisticsByDeviceWithTimeSlot/month').then(res => {
                this.monthData = res
                this.$nextTick(() => {
                    this.charts.setOption(this.option)
                })
            })
        }
    },
    mounted() {
        this.initEcharts()
        this.loadData()
        this.timer = setInterval(() => {
            this.loadData()
        }, 30000);
    },
    unmounted() {
        if (this.charts != null) {
            this.charts.dispose()
            this.charts = null
        }
        if (this.timer != null) {
            clearInterval(this.timer)
            this.timer = null
        }
    }
}
</script>
<style scoped>
#timeCurve {
    width: 100%;
    height: 100%;
}
</style>