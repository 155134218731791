<template>
    <div id="deviceFrequency"></div>
</template>
<script>
export default {
    data() {
        return {
            timer: null,
            charts: null,
            timeSlot: 'today',
            data: []
        }
    },
    computed: {
        option() {
            let xData = []
            let yData = []
            this.data.forEach(e => {
                xData.push(e.name)
                yData.push(e.value)
            })
            return {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                        type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
                    }
                },
                grid: {
                    left: '0%',
                    right: '0%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        axisLine: {
                            lineStyle: {
                                color: '#fff'
                            }
                        },
                        axisLabel: {
                            interval: 0,
                            rotate: -45
                        },
                        data: xData,
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        axisLine: {
                            show: false,
                            lineStyle: {
                                color: '#fff'
                            }
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#2c3960'
                            }
                        }
                    }
                ],
                series: [
                    {
                        name: '直接访问',
                        type: 'bar',
                        barWidth: '15px',
                        itemStyle: {
                            color: '#08e2ed'
                        },
                        data: yData,
                    }
                ]
            }
        }
    },
    methods: {
        initEcharts() {
            this.charts = this.$echarts.init(document.getElementById('deviceFrequency'))
        },
        loadData() {
            this.$http.get('/alarmStatisticsByDeviceWithTimeSlot/'+this.timeSlot).then(res => {
                this.data = res
                this.$nextTick(() => {
                    this.charts.setOption(this.option)
                })
            })
        }
    },
    mounted() {
        this.initEcharts()
        this.loadData()
        this.timer = setInterval(() => {
            this.loadData()
        }, 30000);
    },
    unmounted() {
        if (this.charts != null) {
            this.charts.dispose()
            this.charts = null
        }
        if (this.timer != null) {
            clearInterval(this.timer)
            this.timer = null
        }
    }
}
</script>
<style scoped>
#deviceFrequency {
    width: 100%;
    height: 100%;
}
</style>