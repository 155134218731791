<template>
    <div id="devicePercent"></div>
</template>
<script>
export default {
    data() {
        return {
            timer: null,
            chartsTimer: null,
            chartsSpeed: 3000,
            charts: null,
            currentIndex: 0,
            echartsData: [],
            colors: ['#4f7ddc','#ffb734','#7e62b5','#db894a','#c75b47','#5fadd1','#516284','#d3a41b'],
        }
    },
    computed: {
        option() {
            let seriesData = []
            this.echartsData.forEach((e, i) => {
                let colorIndex = i
                if (i >= this.colors.length) {
                    colorIndex = Math.floor(Math.random() * this.colors.length)
                }
                const item = {'value':e.value, 'name': e.name, itemStyle: {
                    color: this.colors[colorIndex]
                }}
                seriesData.push(item)
            })
            return {
                tooltip: {
                    trigger: 'item',
                    formatter: '{a} <br/>{b} : {c} ({d}%)'
                },
                series: [
                    {
                        name: '设备告警占比',
                        type: 'pie',
                        radius: '65%',
                        center: ['45%', '50%'],
                        data: seriesData,
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            }
        }
    },
    methods: {
        initEcharts() {
            this.charts = this.$echarts.init(document.getElementById('devicePercent'))
        },
        autoHighlight() {
            this.chartsTimer = setInterval(() => {
                // 取消前面高亮
                this.charts.dispatchAction({
                    type: 'downplay',
                    seriesIndex: 0,
                    dataIndex: this.currentIndex
                })
                //
                this.currentIndex = (this.currentIndex + 1) % this.option.series[0].data.length
                // 高亮当前图形
                this.charts.dispatchAction({
                    type: 'highlight',
                    seriesIndex: 0,
                    dataIndex: this.currentIndex
                })
            }, this.chartsSpeed)
        },
        bindMouse() {
            this.charts.on('mouseover', (e) => {
                if (e.dataIndex != this.currentIndex) {
                    this.charts.dispatchAction({
                        type: 'downplay',
                        seriesIndex: 0,
                        dataIndex: this.currentIndex
                    })
                }
            })
            this.charts.on('mouseout', (e) => {
                this.currentIndex = e.dataIndex
                this.charts.dispatchAction({
                    type: 'highlight',
                    seriesIndex: 0,
                    dataIndex: e.dataIndex
                })
            })
        },
        loadData() {
            this.$http.get('/alarmDevicePercent').then(res => {
                this.echartsData = res
                this.charts.setOption(this.option)
            })
        }
    },
    mounted() {
        this.initEcharts()
        this.loadData()
        this.timer = setInterval(() => {
            this.loadData()
        }, 30000)
        this.$nextTick(() => {
            this.charts.dispatchAction({
                type: 'highlight',
                seriesIndex: 0,
                dataIndex: this.currentIndex
            })
            this.autoHighlight()
            this.bindMouse()
        })
    },
    unmounted() {
        if (this.charts != null) {
            this.charts.dispose()
            this.charts = null
        }
        if (this.chartsTimer != null) {
            clearInterval(this.chartsTimer)
            this.chartsTimer = null
        }
        if (this.timer != null) {
            clearInterval(this.timer)
            this.timer = null
        }
    }
}
</script>
<style scoped>
#devicePercent {
    width: 100%;
    height: 100%;
}
</style>