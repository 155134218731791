<template>
    <div class="screen-adapter" :style="style">
        <slot />
    </div>
</template>
<script>
export default {
    name: 'screenAdapter',
    props: {
        width: {
            type: String,
            default: '1920'
        },
        height: {
            type: String,
            default: '1080'
        }
    },
    data() {
        return {
            style: {
                width: this.width + 'px',
                height: this.height + 'px',
                transform: 'scale(1) translate(-50%, -50%)'
            }
        }
    },
    mounted() {
        this.setScale()
        window.onresize = this.Debounce(this.setScale, 300)
    },
    methods: {
        Debounce: (fn, t) => {
            const delay = t || 500
            let timer
            return function() {
                const args = arguments
                if (timer) {
                    clearTimeout(timer)
                }
                const ctx = this
                timer = setTimeout(() => {
                    timer = null
                    fn.apply(ctx, args)
                }, delay)
            }
        },
        // 获取放大缩小比例
        getScale() {
            const w = window.innerWidth / this.width
            const h = window.innerHeight / this.height
            return w < h ? w : h
        },
        // 设置比例
        setScale() {
            this.style.transform = 'scale(' + this.getScale() + ') translate(-50%, -50%)'
        }
    }
}
</script>
<style scoped>
.screen-adapter {
    transform-origin: 0 0;
    position: absolute;
    left: 50%;
    top: 50%;
    transition: 0.3s;
    background: #0B0E2F;
}
</style>