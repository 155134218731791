<template>
	<div class="realtime-data">
		<!-- 卧式储罐 -->
		<div class="name-wscg" style="top: 115px; left: 140px;">卧式储罐</div>
		<div class="point-1" style="top: 55px; left: 140px;">{{D13}}</div>
		<!-- 过滤器 -->
		<div class="name-glq-1" style="top: 60px; left: 225px;">1#过滤器</div>
		<div class="name-glq-2" style="top: 60px; left: 280px;">2#过滤器</div>
		<div style="top: 30px; left: 460px;">{{D9}}</div>
		<div style="top: 70px; left: 430px;">{{D10}}</div>
		<!-- 1#油泵 -->
		<div style="top: 320px; left: 280px;">1#油泵</div>
		<div style="top: 330px; left: 190px;">1#油泵电流</div>
		<div style="top: 255px; left: 315px;">{{D2}}</div>
		<div style="top: 235px; left: 90px;">{{D3}}</div>
		<div style="top: 300px; left: 190px;">{{D4}}</div>
		<!-- 2#油泵 -->
		<div style="top: 415px; left: 280px;">2#油泵</div>
		<div style="top: 425px; left: 190px;">2#油泵电流</div>
		<div style="top: 350px; left: 315px;">{{D6}}</div>
		<div style="top: 330px; left: 90px;">{{D7}}</div>
		<div style="top: 392px; left: 190px;">{{D8}}</div>
		<!-- 1#储油罐 -->
		<div style="top: 220px; left: 595px;">1#储油罐</div>
		<div style="top: 120px; left: 650px;">{{D14}}</div>
		<div style="top: 200px; left: 650px;">{{D15}}</div>
		<div style="top: 160px; left: 595px;">{{D16}}</div>
		<!-- 2#储油罐 -->
		<div style="top: 370px; left: 520px;">{{D17}}</div>
		<div style="top: 440px; left: 520px;">{{D18}}</div>
		<div style="top: 410px; left: 600px;">{{D19}}</div>
		<div style="top: 465px; left: 600px;">2#储油罐</div>
		<!-- 卸油池 -->
		<div style="top: 390px; left: 690px;">卸油池</div>
		<div style="top: 420px; left: 690px;">{{D11}}</div>
		<div style="top: 440px; left: 690px;">{{D12}}</div>
	</div>
</template>
<script>
export default {
	data() {
		return {
			timer: null,
			data: []
		}
	},
	computed: {
		D1() {
			// 1#油泵，运行状态
			return this.getValue("vzwiYDPp","runningState")
		},
		D2() {
			// 1#油泵，温度
			return this.getValue("vzwiYDPp","temperature")
		},
		D3() {
			// 1#油泵，压力
			return this.getValue("vzwiYDPp","pressure")
		},
		D4() {
			// 1#油泵，电流
			return this.getValue("vzwiYDPp","electricCurrent")
		},
		D5() {
			// 2#油泵，运行状态
			return this.getValue("oOKbMGpl","runningState")
		},
		D6() {
			// 2#油泵，温度
			return this.getValue("oOKbMGpl","temperature")
		},
		D7() {
			// 2#油泵，压力
			return this.getValue("oOKbMGpl","pressure")
		},
		D8() {
			// 2#油泵，电流
			return this.getValue("oOKbMGpl","electricCurrent")
		},
		D9() {
			// 过滤器，出口压力
			return this.getValue("NLcbR0mc","outletPressure")
		},
		D10() {
			// 过滤器，入口压力
			return this.getValue("NLcbR0mc","inletPressure")
		},
		D11() {
			// 卸油池，液位
			return this.getValue("g1Qvg68O","liquidLevel")
		},
		D12() {
			// 卸油池，温度
			return this.getValue("g1Qvg68O","temperature")
		},
		D13() {
			// 卧式储罐，液位
			return this.getValue("2vHEag9i","liquidLevel")
		},
		D14() {
			// 1#储油罐，上端温度
			return this.getValue("86K2NMIH","hypomereTemperature")
		},
		D15() {
			// 1#储油罐，下端温度
			return this.getValue("86K2NMIH","hypomereTemperature")
		},
		D16() {
			// 1#储油罐，液位
			return this.getValue("86K2NMIH","liquidLevel")
		},
		D17() {
			// 2#储油罐，上端温度
			return this.getValue("aASNwyR9","upperTemperature")
		},
		D18() {
			// 2#储油罐，下端温度
			return this.getValue("aASNwyR9","hypomereTemperature")
		},
		D19() {
			// 2#储油罐，液位
			return this.getValue("aASNwyR9","liquidLevel")
		}
	},
	methods: {
		loadData() {
			this.$http.get('/realtimeDeviceValue').then(res => {
				this.data = res
			})
		},
		getValue(code, identifier) {
			let value = ''
			this.data.forEach(e => {
				if( e.deviceCode == code && e.valueTypeIdentifier == identifier) {
					value = e.value
				}
			})
			return value
		}
	},
	mounted() {
		this.loadData()
		this.timer = setInterval(() => {
			this.loadData()
		}, 30000);
	},
	unmounted() {
		if( this.timer != null) {
			clearInterval(this.timer)
			this.timer = null
		}
	}
}
</script>
<style scoped>
.realtime-data {
	display: block;
	width: 100%;
	height: 100%;
	position: relative;
	font-size: 12px;
	color: #ffffff;
}
.realtime-data > div {
	display: inline-block;
	line-height: 1;
	position: absolute;
}
</style>